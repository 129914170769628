@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  @font-face {
    font-family: HelveticaNeueMedium;
    src: url('./assets/fonts/helvetica-neue-55/HelveticaNeue-Medium.otf');
  }

  @font-face {
    font-family: HelveticaNeueMediumExt;
    src: url('./assets/fonts/helvetica-neue-55/HelveticaNeue-MediumExt.otf');
  }

  @font-face {
    font-family: DarkerGrotesqueRegular;
    src: url('./assets/fonts/Darker_Grotesque/DarkerGrotesque-Regular.ttf');
  }

  @font-face {
    font-family: DidactGothicRegular;
    src: url('./assets/fonts/Didact_Gothic/DidactGothic-Regular.ttf');
  }
}


:root {
  --HelveticaNeueMedium: HelveticaNeueMedium;
  --DarkerGrotesqueRegular: DarkerGrotesqueRegular;
  --DidactGothicRegular: DidactGothicRegular;
  --HelveticaNeueMediumExt: HelveticaNeueMediumExt;
  --text-color: #fff;
  --text-color1: #000;
  --text-color2: #3D8DDA;
  --text-color3: #A3A3A3;
  --bg-color: #fff;
  --bg-color1: #000;
  --bg-color2: #3D8DDA;
  --bg-color3: #A3A3A3;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--bg-color);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

body {
  background-color: #000 !important;
}

.p-l-r {
  padding-left: 60px;
  padding-right: 60px;
}

.p-l-r-1 {
  padding-left: 120px;
  padding-right: 120px;
}

.p-l {
  padding-left: 60px;
}

.p-r {
  padding-right: 60px;
}

.top {
  padding-top: 80px;
}

.bottom {
  padding-bottom: 80px;
}

.top_1 {
  padding-top: 100px;
}

.bottom_1 {
  padding-bottom: 100px;
}

.martop {
  margin-top: 60px;
}

.heading-1 {
  font-size: 36px;
  font-weight: 500;
  font-family: var(--HelveticaNeueMediumExt);
}

.paragraph {
  font-family: var(--DidactGothicRegular);
  font-size: 18px;
  font-weight: 400;
  line-height: 26.4px;


}

.sub-heading {
  font-family: var(--HelveticaNeueMediumExt);
  font-size: 22px;
  font-style: italic;
  font-weight: 400;
  line-height: 28.38px;
  text-align: left;


}

.header-title .heading-1 {
  line-height: 45px;
}

header {
  background-color: var(--bg-color);
}



#mobile-menu {
  position: fixed;
  background: #000;
  width: 100%;
  right: 0;
  top: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
}

#mobile-menu.hide {
  right: -100%;
}

#mobile-menu.show {
  right: 0;
}

.service-list {
  position: relative;
}

.service-list ul li {
  position: relative;
}

.service-list ul li:last-child {
  border-bottom: none;
}

.service-list ul li a {
  padding: 60px 100px;
  border-bottom: 1px solid #fff;
  position: relative;
  transition: 0.5s;
}

.service-list ul li a::after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--bg-color2);
  transition: 0.5s;
  transform: scale(1, 0);
}

.service-list ul li a:hover::after {
  transform: none;
}

.service-list ul li a p {
  position: relative;
  width: fit-content;
  margin-bottom: 0;
  z-index: 1;
}

.service-list ul li a span {
  position: absolute;
  top: 0;
  left: -34px;
  font-size: 16px;
  font-family: var(--HelveticaNeueMediumExt);
}

.projects-section {
  position: relative;

}

.projects-section .product-img {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.projects-section .product-img img {
  transition: 0.5s;
}

.projects-section .product-img:hover img {
  transform: scale(1.1);
}

.view-all-work {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.about-section-2 {
  position: relative;
}

.about-section-2 .about-img {
  width: 100%;
  overflow: hidden;
}

.about-section-2 .about-img img {
  width: 100%;
  transition: 0.5s;
}

.about-section-2 .about-img:hover img {
  transform: scale(1.1);
}

.news-section .left-section {
  overflow: hidden;
}

.news-section .left-section img {
  transition: 0.5s;
}

.news-section .left-section:hover img {
  transform: scale(1.1);
}

.right-news-section {
  position: relative;
  /* height: 450px; */
  overflow: hidden;
}

.right-news-section li {
  list-style: none;
  padding: 20px 0;
  position: relative;
  cursor: pointer;
}


.right-news-section li::after {
  position: absolute;
  content: '';
  bottom: 5px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #000;
}

.right-news-section li:last-child:after {
  display: none;
}

.right-news-section h5 {
  font-size: 18px;
  margin-bottom: 10px;
  font-family: var(--HelveticaNeueMediumExt);
}

.right-news-section p {
  position: relative;
}

.client_section {
  position: relative;
  padding-top: 50px;
  padding-bottom: 50px;
}

.client_section .client_logo {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.client_section .client_logo img {
  width: 76%;
}

.client_section h6 {
  text-align: center;
  color: var(--text-color);
  font-size: 14px;
  font-family: var(--DidactGothicRegular);
}

.footer-section {
  position: relative;
}

.footer-section .quick-form {
  position: relative;
}

.footer-section .quick-form form {
  position: relative;
  margin-top: 20px;
}

.footer-section .quick-form form input {
  background-color: transparent !important;
  border: none;

  border-radius: 0 !important;
  width: 70%;
  padding: 10px 0;
  outline: none !important;
  padding-left: 10px;
}

.footer-section .quick-form form input::placeholder {
  color: #fff;
}

.footer-section .quick-form form input:focus-visible {
  box-shadow: none !important;
  border: none !important;

  outline: none !important;
}

.footer-section .quick-form form .input_field {
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #fff !important;
  width: 70%;
}

.footer-section .quick-form form .input_field label {

  display: flex;
  align-items: center;
  color: #fff;
  font-family: var(--HelveticaNeueMediumExt);
  font-size: 20px;
  width: 100px;
}

.footer-section .quick-form form .input_field label.active {}

.footer-section .quick-form form .input_field label img {
  margin-left: 10px;
  width: 20px;
}

.footer-left-section {
  position: relative;
  padding-right: 80px;
  color: var(--text-color);
  margin-top: 30px;
}

.footer-left-section .footer-logo {
  position: relative;
  width: 250px;
  margin-bottom: 30px;
}

.footer-left-section .footer-logo img {
  position: relative;
  width: 100%;
}

.button_animation {
  position: relative;
  width: fit-content;
  overflow: hidden;
  transition: 0.6s;
}

.button_animation .left_icon {
  position: absolute;
  left: -50px;
  top: 40%;
  transform: translateY(-50%);
  transition: 0.6s;
}

.button_animation:hover {
  padding-left: 30px;
}

.button_animation:hover .left_icon {
  left: -5px;
}

.button_animation .right_icon {
  transition: 0.6s;
}

.button_animation:hover .right_icon {
  transform: translateX(50px);
  opacity: 0;
}

.footer-copyright {
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 50px;
  padding-top: 40px;
  margin-top: 80px;
  border-top: 1px solid #fff;
}

.copy-right-p p {
  text-align: left;
}

.footer-copyright p {
  margin-bottom: 0;
  color: #fff;
}

.privacy-policy p {
  text-align: center;
}

.footer-copyright p {
  margin-bottom: 0;
}

.website-link p {
  text-align: right;
}

.footer-copyright p {
  margin-bottom: 0;
}

.footer-copyright a {
  color: #fff;
  text-decoration: none;
}


.btn--2 {
  overflow: hidden;

  position: relative;

}

.btn--2 .btn__text {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 100%;
  line-height: 45px;
  transition: top 0.3s;
}

.btn_1 {
  height: 45px;
  width: 160px;
  margin-left: 20px;
}

.btn_2 {
  height: 45px;
  width: 160px;
  text-align: center;
}

.btn_3 {
  height: 45px;
  width: 160px;
  text-align: center;
}

.btn_1::after {
  content: "Contact Us";
}


.btn--2::after {
  position: absolute;
  top: 100%;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  line-height: 45px;
  background-color: var(--bg-color2);
  transition: top 0.3s;
  font-size: 16px;
  font-family: var(--HelveticaNeueMediumExt);
  font-weight: 700;
  color: #fff;

}

.btn_2::after {
  content: "View All Work";
  text-align: center;
  font-size: 12px;
}

.btn_3::after {
  content: "Contact Us";
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  font-family: var(--HelveticaNeueMediumExt);
}

.btn--2:hover::after {
  top: 0;
}

.btn--2:hover>.btn__text {
  top: -100%;
}

.inner-page-section {
  position: relative;
}

.breadcrumb {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.breadcrumb li {
  list-style: none;
  padding-right: 10px;
  position: relative;
  font-family: var(--HelveticaNeueMediumExt);
  font-size: 18px;
  color: #000;
}

.breadcrumb li.active {
  color: var(--text-color2);
}

.breadcrumb li::after {
  position: absolute;
  content: '';
}

.row_list {
  position: relative;
  margin: 80px 0;
}

.row_list:nth-child(even) .right-section .content-section {
  padding-left: 0 !important;
}

.row_list:first-child {
  margin-top: 0;
}

.row_list .left-section {
  position: relative;
}

.row_list .left-section .img-box {
  position: relative;
  width: 100%;
  overflow: hidden;
}


.row_list .left-section .img-box img {
  position: relative;
  width: 90%;
  transition: 0.5s;
}

.row_list .left-section .img-box:hover img {
  transform: scale(1.1);
}

.row_list:nth-child(even) .left-section .img-box {
  display: flex;
  align-items: center;
  justify-content: end;
}

.row_list .content-section {
  position: relative;
  width: 100%;
  padding: 50px;
}

.row_list .content-section a {
  text-decoration: underline;
  color: var(--text-color1);
  display: flex;
  align-items: center;
  margin-top: 20px;
  font-family: var(--HelveticaNeueMediumExt);
  position: relative;
}

.row_list .content-section h3 {
  font-size: 35px;
  font-family: var(--HelveticaNeueMediumExt);
}

.work_list_section {
  position: relative;
}

.work_list_section .work-card {
  position: relative;
}

.work_list_section .work-card .img-card {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.work_list_section .work-card .img-card img {
  position: relative;
  width: 100%;
  transition: 0.6s;
}

.work_list_section .work-card .img-card:hover img {
  transform: scale(1.1);
}

.work_list_section .work-card h4 {
  font-size: 35px;
  font-family: var(--HelveticaNeueMediumExt);
  color: #000;
  margin-top: 10px;
}

.pagination-box ul li button {
  padding: 0 10px;
  font-family: var(--HelveticaNeueMediumExt);
}

.pagination-box ul li:first-child button {
  padding-left: 0;
}

.pagination-box ul li button.active {
  color: #000;
  text-decoration-line: underline;
}

.client_section {
  position: relative;
}

.client_section .client-img {
  position: relative;
  width: 100%;
}

.client_section .client-img img {
  position: relative;
  width: 100%;
}

.client_section .client-details {
  position: relative;
  width: 75%;
  margin: auto;
  padding-top: 0;
  text-align: center;
}

.client_section .client-details p {
  font-family: var(--HelveticaNeueMediumExt);
  font-size: 18px;
  color: #000;
  line-height: 23.2px;
  text-align: center;

}

.client_section .client-details h4 {
  font-size: 18px;
  color: var(--text-color3);
  text-transform: uppercase;
  margin-top: 30px;
  font-family: var(--HelveticaNeueMediumExt);
  margin-bottom: 0;
}

.client_section .client-details h5 {
  font-size: 16px;
  color: var(--text-color3);
  margin-top: 0px;
  font-family: var(--HelveticaNeueMediumExt);
}

.vission-mission-section {
  position: relative;
  transition: 0.5s;
}

.vission-mission-section .v-m-box {
  position: relative;
  text-align: center;
  transition: 0.5s;
  opacity: 0.5;
}

.vission-mission-section .v-m-box:hover {
  opacity: 1;
}

.vission-mission-section .v-m-box h4 {
  font-size: 30px;
  color: #000;
  font-family: var(--HelveticaNeueMediumExt);
  margin-bottom: 25px;
}

.team_section {
  position: relative;
}

.team_section .team_box {
  position: relative;
}

.team_section .team_box .team_profile_img {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.team_section .team_box .team_profile_img img {
  transition: 0.5s;
}

.team_section .team_box:hover .team_profile_img img {
  transform: scale(1.1);
}

.team_section .team_box .team_details {
  position: relative;
  text-align: center;
  padding-top: 15px;
}

.team_section .team_box .team_details h4 {
  font-size: 18px;
  font-family: var(--HelveticaNeueMediumExt);
  color: #000;
  margin-bottom: 0;
  line-height: 18px;
}

.team_section .team_box .team_details h3 {
  margin-bottom: 0;
  color: var(--text-color3);
  font-family: var(--HelveticaNeueMediumExt);
  font-size: 18px;
}

.contact-section .contact-form {
  position: relative;
  background-color: var(--bg-color1);
  padding: 40px 50px;
}

.contact-section .contact-form form .input-field {
  position: relative;
  width: 100%;
  margin: 30px 0;
}

.contact-section .contact-form form .input-field:first-child {
  margin-top: 0;
}

.contact-section .contact-form form .input-field:last-child {
  margin-bottom: 0;
}

.contact-section .contact-form form .input-field .mb-3 {
  width: 100%;
}

.contact-section .contact-form form .input-field input,
.contact-section .contact-form form .input-field select {
  width: 100%;
  border: none;
  border-bottom: 0.8px solid var(--text-color3);
  padding: 10px 0;
  background-color: transparent !important;
  color: #fff;
  font-family: var(--HelveticaNeueMediumExt);
  font-size: 18px;
  font-weight: 500;
  line-height: 23.2px;
  text-align: left;
  outline: none;

}

.contact-section .contact-form form .input-field select option {
  color: #000 !important;
}

.contact-section .contact-form form .input-field input::placeholder {
  color: #fff;
}

.contact-section .contact-form form .input-field button {
  background-color: var(--bg-color);
  color: #000;
  font-size: 16px;
  font-family: var(--HelveticaNeueMediumExt);
  border-radius: 6px;
}

.btn_4 {
  height: 42px;
  width: 140px;
  text-align: center;
}

.btn_4::after {
  content: "Send";
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  font-family: var(--HelveticaNeueMediumExt);
}

.contact-section .left-section {
  background: url('./assets/images/contact.jpg');
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-detail {
  background-color: #3d8ddad6;
  height: 100%;
  width: 100%;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contact-detail .call-to-action {
  display: flex;
  flex-direction: column;
  padding: 30px 0 20px;
  width: 100%;
}

.contact-detail .call-to-action a {
  display: flex;
  flex-direction: column;
  background-color: var(--bg-color1);
  text-decoration: none;
  color: #fff;
  width: fit-content;
  padding: 10px 30px;
  margin-bottom: 30px;
  border-radius: 50px;
  font-family: var(--Syne-Medium);
  font-size: 16px;
  transition: 0.5s;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.contact-detail .call-to-action a i {
  margin-right: 5px;
}

.contact-detail .call-to-action a:hover {
  background-color: var(--bg-color1);
  color: #fff;
}

.contact-detail .social_media {
  position: relative;
}

.contact-detail .social_media p {
  position: relative;
  font-family: var(--HelveticaNeueMediumExt);
  font-size: 16px;
  color: var(--text-color1);
}

.contact-detail .social_media ul {
  display: flex;
  align-items: center;
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.contact-detail .social_media ul li a {
  color: #fff;
  background-color: var(--bg-color1);
  padding: 5px;
  width: 35px;
  height: 35px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  text-decoration: none;
  transition: 0.5s;

}

.contact-detail .social_media ul li a:hover {
  background-color: var(--bg-color1);
  color: #fff;
}

.client-box-img {
  padding: 10px;
}

.client-box-img img {
  filter: invert(100%) brightness(3) saturate(5%);
  height: 60px;
  width: auto;
}

.client-section {
  border-top: 1px solid #5e5e5e;
}

.client-logo-section {
  position: relative;
  padding: 50px;
}

.client-logo-section ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.client-logo-section ul li img {
  width: 100%;
  filter: invert(100%) brightness(3) saturate(5%);
}

.work_list_section .work-card h5 {
  font-family: var(--HelveticaNeueMediumExt);
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 20px;
}


.privacy_policy {
  position: relative;
}

.privacy_policy h3 {
  font-family: var(--HelveticaNeueMediumExt);
  font-size: 30px;
  color: #000;
  margin: 20px 0;
}

.privacy_policy p,
.privacy_policy li {
  margin: 15px 0;
}

.location-list {
  position: relative;
  width: 100%;
}

.location-list ul {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
  padding-top: 20px;
  flex-wrap: wrap;
  background: #141414;
}

.location-list ul li {
  color: #fff;
  font-family: var(--HelveticaNeueMediumExt);
  font-size: 16px;
  padding: 10px 20px;
  position: relative;
}

.location-list ul li::after {
  position: absolute;
  content: '|';
  color: #fff;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  right: 0;
}

.location-list ul li:last-child:after {
  display: none;
}

.location-list ul li:first-child {
  padding-left: 0;
}

.location-list ul li:last-child {
  padding-right: 0;
}


.banner-logo {
  right: 108px;
  top: 95px;
  width: 75px;
  position: absolute;
}
.banner-logo-2 {
  right: 8px;
  bottom: -90px;
  width: 100px;
  position: absolute;
}

@media(max-width:1199px) {
  .heading-1 {
    font-size: 30px;
  }

  .about-section .right-section {
    padding-left: 50px;
  }
}

@media(max-width:991px) {
  .heading-1 {
    font-size: 26px;
  }

  .header-title .heading-1 {
    line-height: 36px;
  }

  .p-l-r-1 {
    padding-left: 50px;
    padding-right: 50px;
  }

  .service-list ul li a {
    padding: 40px 60px;
  }

  .banner-section {
    padding: 50px 0;
  }

  .banner-section .right-section {
    align-items: center;
    text-align: center;
  }

  .banner-section .right-section .heading-1 {
    text-align: center;
  }

  .about-section,
  .service-section {
    flex-direction: column;

  }

  .about-section .left-section,
  .service-section .left-section {
    width: 100%;
  }

  .about-section .right-section,
  .service-section .right-section {
    width: 100%;
  }

  .about-section .right-section,
  .service-section .right-section {
    padding-left: 0;
    padding-top: 10px;
  }

  .footer-copyright {
    flex-wrap: wrap;
  }

  .privacy-policy p,
  .footer-copyright p {
    text-align: center;
  }

  .martop {
    margin-top: 20px;
  }

  .work_list_section .work-card h4 {
    font-size: 30px;
  }

  .row_list .content-section {
    padding: 30px;
  }

  .row_list .left-section .img-box img {
    width: 100%;
  }

  .row_list .content-section h3 {
    font-size: 30px;
  }

  .client_section .client-details {
    width: 100%;
  }

  .vission-mission-section .v-m-box h4 {
    font-size: 26px;
    margin-bottom: 20px;
  }
  .banner-logo-2{
    bottom: 0;
  }
}

@media(max-width:768px) {
  .top {
    padding-top: 40px;
  }

  .bottom {
    padding-bottom: 40px;
  }

  .bottom_1 {
    padding-bottom: 50px;
  }

  .top_1 {
    padding-top: 50px;
  }

  .work_list_section .work-card h4 {
    font-size: 28px;
  }

  .row_list .content-section h3 {
    font-size: 28px;
  }

  .row_list {
    flex-wrap: wrap;
    margin: 40px 0;
  }

  .row_list .left-section,
  .row_list .right-section {
    width: 100%;
  }

  .row_list:nth-child(even) .left-section {
    order: 1;
  }

  .row_list:nth-child(even) .right-section {
    order: 2;
  }
}

@media(max-width:600px){
  .banner-logo-2 {
    right: 8px;
    width: 60px;
    position: absolute;
  }
}

@media(max-width:576px) {
  .heading-1 br {
    display: none;
  }

  .heading-1 {
    font-size: 24px;
  }

  .p-l-r {
    padding-left: 20px;
    padding-right: 20px;
  }

  .p-l-r-1 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .service-list ul li a {
    padding: 30px 60px;
  }


  .right-news-section li {
    margin: 20px 0;
  }

  .right-news-section li:first-child {
    margin-top: 0;
  }

  .right-news-section li:last-child {
    margin-bottom: 0;
  }

  .right-news-section {
    /* height: 350px; */
  }

  .footer-copyright {
    margin-top: 45px;
  }

  .footer-section .quick-form form input {
    width: 100%;
  }

  .footer-section .quick-form form .input_field label {
    font-size: 16px;
  }

  .work_list_section .work-card h4 {
    font-size: 22px;
  }

  .breadcrumb li {
    font-size: 16px;
  }

  .row_list .content-section h3 {
    font-size: 18px;
  }

  .client_section .client-details p {
    font-size: 16px;
  }

  .contact-section .contact-form form .input-field input,
  .contact-section .contact-form form .input-field select {
    font-size: 14px;
  }

  .contact-section .contact-form {
    padding: 20px 30px;
  }

  .banner-logo {
    right: 14px;
    top: 75px;
    width: 50px;
    position: absolute;
  }
 
  
}


@media(max-width:320px) {
  .heading-1 {
    font-size: 18px;
  }

  .sub-heading {
    font-size: 18px;
  }
  .banner-logo-2 {
    right: 0;
    bottom: -28px;
    width: 50px;
    position: absolute;
}
}