.banner_left svg {
  display: block;
  margin: 0 auto;
  width: 385px;
  height: 430px;
}

.st0,
.st1,
.st2 {
  animation: pulse 4s infinite;
}

.st0 {
  fill: #074874;
}

.st1 {
  fill: #0F79BE;
}

.st2 {
  fill: #00ADEE;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.1;
  }

  100% {
    opacity: 1;
  }
}

path:nth-child(0) {
  animation-delay: 0s;
}

path:nth-child(1) {
  animation-delay: 0.05s;
}

path:nth-child(2) {
  animation-delay: 0.1s;
}

path:nth-child(3) {
  animation-delay: 0.15s;
}

path:nth-child(4) {
  animation-delay: 0.2s;
}

path:nth-child(5) {
  animation-delay: 0.25s;
}

path:nth-child(6) {
  animation-delay: 0.3s;
}

path:nth-child(7) {
  animation-delay: 0.35s;
}

path:nth-child(8) {
  animation-delay: 0.4s;
}

path:nth-child(9) {
  animation-delay: 0.45s;
}

path:nth-child(10) {
  animation-delay: 0.5s;
}

path:nth-child(11) {
  animation-delay: 0.55s;
}

path:nth-child(12) {
  animation-delay: 0.6s;
}

path:nth-child(13) {
  animation-delay: 0.65s;
}

path:nth-child(14) {
  animation-delay: 0.7s;
}

path:nth-child(15) {
  animation-delay: 0.75s;
}

path:nth-child(16) {
  animation-delay: 0.8s;
}

path:nth-child(17) {
  animation-delay: 0.85s;
}

path:nth-child(18) {
  animation-delay: 0.9s;
}

path:nth-child(19) {
  animation-delay: 0.95s;
}

path:nth-child(20) {
  animation-delay: 1s;
}

path:nth-child(21) {
  animation-delay: 1.05s;
}

path:nth-child(22) {
  animation-delay: 1.1s;
}

path:nth-child(23) {
  animation-delay: 1.15s;
}

path:nth-child(24) {
  animation-delay: 1.2s;
}

path:nth-child(25) {
  animation-delay: 1.25s;
}

path:nth-child(26) {
  animation-delay: 1.3s;
}

path:nth-child(27) {
  animation-delay: 1.35s;
}

path:nth-child(28) {
  animation-delay: 1.4s;
}

path:nth-child(29) {
  animation-delay: 1.45s;
}

path:nth-child(30) {
  animation-delay: 1.5s;
}

path:nth-child(31) {
  animation-delay: 1.55s;
}

path:nth-child(32) {
  animation-delay: 1.6s;
}

path:nth-child(33) {
  animation-delay: 1.65s;
}

path:nth-child(34) {
  animation-delay: 1.7s;
}

path:nth-child(35) {
  animation-delay: 1.75s;
}

path:nth-child(36) {
  animation-delay: 1.8s;
}

path:nth-child(37) {
  animation-delay: 1.85s;
}

path:nth-child(38) {
  animation-delay: 1.9s;
}

path:nth-child(39) {
  animation-delay: 1.95s;
}

path:nth-child(40) {
  animation-delay: 2s;
}

path:nth-child(41) {
  animation-delay: 2.05s;
}

path:nth-child(42) {
  animation-delay: 2.1s;
}

path:nth-child(43) {
  animation-delay: 2.15s;
}

path:nth-child(44) {
  animation-delay: 2.2s;
}

path:nth-child(45) {
  animation-delay: 2.25s;
}

path:nth-child(46) {
  animation-delay: 2.3s;
}

path:nth-child(47) {
  animation-delay: 2.35s;
}

path:nth-child(48) {
  animation-delay: 2.4s;
}

path:nth-child(49) {
  animation-delay: 2.45s;
}

path:nth-child(50) {
  animation-delay: 2.5s;
}

path:nth-child(51) {
  animation-delay: 2.55s;
}

path:nth-child(52) {
  animation-delay: 2.6s;
}

path:nth-child(53) {
  animation-delay: 2.65s;
}

path:nth-child(54) {
  animation-delay: 2.7s;
}

path:nth-child(55) {
  animation-delay: 2.75s;
}

path:nth-child(56) {
  animation-delay: 2.8s;
}

path:nth-child(57) {
  animation-delay: 2.85s;
}

path:nth-child(58) {
  animation-delay: 2.9s;
}

path:nth-child(59) {
  animation-delay: 2.95s;
}

path:nth-child(60) {
  animation-delay: 3s;
}

path:nth-child(61) {
  animation-delay: 3.05s;
}

path:nth-child(62) {
  animation-delay: 3.1s;
}

path:nth-child(63) {
  animation-delay: 3.15s;
}

path:nth-child(64) {
  animation-delay: 3.2s;
}

path:nth-child(65) {
  animation-delay: 3.25s;
}

path:nth-child(66) {
  animation-delay: 3.3s;
}

path:nth-child(67) {
  animation-delay: 3.35s;
}

path:nth-child(68) {
  animation-delay: 3.4s;
}

path:nth-child(69) {
  animation-delay: 3.45s;
}

path:nth-child(70) {
  animation-delay: 3.5s;
}

path:nth-child(71) {
  animation-delay: 3.55s;
}

path:nth-child(72) {
  animation-delay: 3.6s;
}

path:nth-child(73) {
  animation-delay: 3.65s;
}

path:nth-child(74) {
  animation-delay: 3.7s;
}

path:nth-child(75) {
  animation-delay: 3.75s;
}

path:nth-child(76) {
  animation-delay: 3.8s;
}

path:nth-child(77) {
  animation-delay: 3.85s;
}

path:nth-child(78) {
  animation-delay: 3.9s;
}

path:nth-child(79) {
  animation-delay: 3.95s;
}

path:nth-child(80) {
  animation-delay: 4s;
}

path:nth-child(81) {
  animation-delay: 4.05s;
}

path:nth-child(82) {
  animation-delay: 4.1s;
}

path:nth-child(83) {
  animation-delay: 4.15s;
}

path:nth-child(84) {
  animation-delay: 4.2s;
}

path:nth-child(85) {
  animation-delay: 4.25s;
}

path:nth-child(86) {
  animation-delay: 4.3s;
}

path:nth-child(87) {
  animation-delay: 4.35s;
}

path:nth-child(88) {
  animation-delay: 4.4s;
}

path:nth-child(89) {
  animation-delay: 4.45s;
}

path:nth-child(90) {
  animation-delay: 4.5s;
}

path:nth-child(91) {
  animation-delay: 4.55s;
}

path:nth-child(92) {
  animation-delay: 4.6s;
}

path:nth-child(93) {
  animation-delay: 4.65s;
}

path:nth-child(94) {
  animation-delay: 4.7s;
}

path:nth-child(95) {
  animation-delay: 4.75s;
}

path:nth-child(96) {
  animation-delay: 4.8s;
}

path:nth-child(97) {
  animation-delay: 4.85s;
}

path:nth-child(98) {
  animation-delay: 4.9s;
}

path:nth-child(99) {
  animation-delay: 4.95s;
}

path:nth-child(100) {
  animation-delay: 5s;
}

path:nth-child(101) {
  animation-delay: 5.05s;
}

path:nth-child(102) {
  animation-delay: 5.1s;
}

path:nth-child(103) {
  animation-delay: 5.15s;
}

path:nth-child(104) {
  animation-delay: 5.2s;
}

path:nth-child(105) {
  animation-delay: 5.25s;
}

path:nth-child(106) {
  animation-delay: 5.3s;
}

path:nth-child(107) {
  animation-delay: 5.35s;
}

path:nth-child(108) {
  animation-delay: 5.4s;
}

path:nth-child(109) {
  animation-delay: 5.45s;
}

path:nth-child(110) {
  animation-delay: 5.5s;
}

path:nth-child(111) {
  animation-delay: 5.55s;
}

path:nth-child(112) {
  animation-delay: 5.6s;
}

path:nth-child(113) {
  animation-delay: 5.65s;
}

path:nth-child(114) {
  animation-delay: 5.7s;
}

path:nth-child(115) {
  animation-delay: 5.75s;
}

path:nth-child(116) {
  animation-delay: 5.8s;
}

path:nth-child(117) {
  animation-delay: 5.85s;
}

path:nth-child(118) {
  animation-delay: 5.9s;
}

path:nth-child(119) {
  animation-delay: 5.95s;
}

path:nth-child(120) {
  animation-delay: 6s;
}

path:nth-child(121) {
  animation-delay: 6.05s;
}

path:nth-child(122) {
  animation-delay: 6.1s;
}

path:nth-child(123) {
  animation-delay: 6.15s;
}

path:nth-child(124) {
  animation-delay: 6.2s;
}

path:nth-child(125) {
  animation-delay: 6.25s;
}

path:nth-child(126) {
  animation-delay: 6.3s;
}

path:nth-child(127) {
  animation-delay: 6.35s;
}

path:nth-child(128) {
  animation-delay: 6.4s;
}

path:nth-child(129) {
  animation-delay: 6.45s;
}

path:nth-child(130) {
  animation-delay: 6.5s;
}

path:nth-child(131) {
  animation-delay: 6.55s;
}

path:nth-child(132) {
  animation-delay: 6.6s;
}

path:nth-child(133) {
  animation-delay: 6.65s;
}

path:nth-child(134) {
  animation-delay: 6.7s;
}

path:nth-child(135) {
  animation-delay: 6.75s;
}

path:nth-child(136) {
  animation-delay: 6.8s;
}

path:nth-child(137) {
  animation-delay: 6.85s;
}

path:nth-child(138) {
  animation-delay: 6.9s;
}

path:nth-child(139) {
  animation-delay: 6.95s;
}

path:nth-child(140) {
  animation-delay: 7s;
}

path:nth-child(141) {
  animation-delay: 7.05s;
}

path:nth-child(142) {
  animation-delay: 7.1s;
}

path:nth-child(143) {
  animation-delay: 7.15s;
}

path:nth-child(144) {
  animation-delay: 7.2s;
}

path:nth-child(145) {
  animation-delay: 7.25s;
}

path:nth-child(146) {
  animation-delay: 7.3s;
}

path:nth-child(147) {
  animation-delay: 7.35s;
}

path:nth-child(148) {
  animation-delay: 7.4s;
}

path:nth-child(149) {
  animation-delay: 7.45s;
}

path:nth-child(150) {
  animation-delay: 7.5s;
}

path:nth-child(151) {
  animation-delay: 7.55s;
}

path:nth-child(152) {
  animation-delay: 7.6s;
}

path:nth-child(153) {
  animation-delay: 7.65s;
}

path:nth-child(154) {
  animation-delay: 7.7s;
}

path:nth-child(155) {
  animation-delay: 7.75s;
}

path:nth-child(156) {
  animation-delay: 7.8s;
}

path:nth-child(157) {
  animation-delay: 7.85s;
}

path:nth-child(158) {
  animation-delay: 7.9s;
}

path:nth-child(159) {
  animation-delay: 7.95s;
}

path:nth-child(160) {
  animation-delay: 8s;
}

path:nth-child(161) {
  animation-delay: 8.05s;
}

path:nth-child(162) {
  animation-delay: 8.1s;
}

path:nth-child(163) {
  animation-delay: 8.15s;
}

path:nth-child(164) {
  animation-delay: 8.2s;
}

path:nth-child(165) {
  animation-delay: 8.25s;
}

path:nth-child(166) {
  animation-delay: 8.3s;
}

path:nth-child(167) {
  animation-delay: 8.35s;
}

path:nth-child(168) {
  animation-delay: 8.4s;
}

path:nth-child(169) {
  animation-delay: 8.45s;
}

path:nth-child(170) {
  animation-delay: 8.5s;
}

path:nth-child(171) {
  animation-delay: 8.55s;
}

path:nth-child(172) {
  animation-delay: 8.6s;
}

path:nth-child(173) {
  animation-delay: 8.65s;
}

path:nth-child(174) {
  animation-delay: 8.7s;
}

path:nth-child(175) {
  animation-delay: 8.75s;
}

path:nth-child(176) {
  animation-delay: 8.8s;
}

path:nth-child(177) {
  animation-delay: 8.85s;
}

path:nth-child(178) {
  animation-delay: 8.9s;
}

path:nth-child(179) {
  animation-delay: 8.95s;
}

path:nth-child(180) {
  animation-delay: 9s;
}

path:nth-child(181) {
  animation-delay: 9.05s;
}

path:nth-child(182) {
  animation-delay: 9.1s;
}

path:nth-child(183) {
  animation-delay: 9.15s;
}

path:nth-child(184) {
  animation-delay: 9.2s;
}

path:nth-child(185) {
  animation-delay: 9.25s;
}

path:nth-child(186) {
  animation-delay: 9.3s;
}

path:nth-child(187) {
  animation-delay: 9.35s;
}

path:nth-child(188) {
  animation-delay: 9.4s;
}

path:nth-child(189) {
  animation-delay: 9.45s;
}

path:nth-child(190) {
  animation-delay: 9.5s;
}

path:nth-child(191) {
  animation-delay: 9.55s;
}

path:nth-child(192) {
  animation-delay: 9.6s;
}

path:nth-child(193) {
  animation-delay: 9.65s;
}

path:nth-child(194) {
  animation-delay: 9.7s;
}

path:nth-child(195) {
  animation-delay: 9.75s;
}

path:nth-child(196) {
  animation-delay: 9.8s;
}

path:nth-child(197) {
  animation-delay: 9.85s;
}

path:nth-child(198) {
  animation-delay: 9.9s;
}

path:nth-child(199) {
  animation-delay: 9.95s;
}

path:nth-child(200) {
  animation-delay: 10s;
}

path:nth-child(201) {
  animation-delay: 10.05s;
}

path:nth-child(202) {
  animation-delay: 10.1s;
}

path:nth-child(203) {
  animation-delay: 10.15s;
}

path:nth-child(204) {
  animation-delay: 10.2s;
}

path:nth-child(205) {
  animation-delay: 10.25s;
}

path:nth-child(206) {
  animation-delay: 10.3s;
}

path:nth-child(207) {
  animation-delay: 10.35s;
}

path:nth-child(208) {
  animation-delay: 10.4s;
}

path:nth-child(209) {
  animation-delay: 10.45s;
}

path:nth-child(210) {
  animation-delay: 10.5s;
}

path:nth-child(211) {
  animation-delay: 10.55s;
}

path:nth-child(212) {
  animation-delay: 10.6s;
}

path:nth-child(213) {
  animation-delay: 10.65s;
}

path:nth-child(214) {
  animation-delay: 10.7s;
}

path:nth-child(215) {
  animation-delay: 10.75s;
}

path:nth-child(216) {
  animation-delay: 10.8s;
}

path:nth-child(217) {
  animation-delay: 10.85s;
}

path:nth-child(218) {
  animation-delay: 10.9s;
}

path:nth-child(219) {
  animation-delay: 10.95s;
}

path:nth-child(220) {
  animation-delay: 11s;
}

path:nth-child(221) {
  animation-delay: 11.05s;
}

path:nth-child(222) {
  animation-delay: 11.1s;
}

path:nth-child(223) {
  animation-delay: 11.15s;
}

path:nth-child(224) {
  animation-delay: 11.2s;
}

path:nth-child(225) {
  animation-delay: 11.25s;
}

path:nth-child(226) {
  animation-delay: 11.3s;
}

path:nth-child(227) {
  animation-delay: 11.35s;
}

path:nth-child(228) {
  animation-delay: 11.4s;
}

path:nth-child(229) {
  animation-delay: 11.45s;
}

path:nth-child(230) {
  animation-delay: 11.5s;
}

path:nth-child(231) {
  animation-delay: 11.55s;
}

path:nth-child(232) {
  animation-delay: 11.6s;
}

path:nth-child(233) {
  animation-delay: 11.65s;
}

path:nth-child(234) {
  animation-delay: 11.7s;
}

path:nth-child(235) {
  animation-delay: 11.75s;
}

path:nth-child(236) {
  animation-delay: 11.8s;
}

path:nth-child(237) {
  animation-delay: 11.85s;
}

path:nth-child(238) {
  animation-delay: 11.9s;
}

path:nth-child(239) {
  animation-delay: 11.95s;
}

path:nth-child(240) {
  animation-delay: 12s;
}

path:nth-child(241) {
  animation-delay: 12.05s;
}

path:nth-child(242) {
  animation-delay: 12.1s;
}

path:nth-child(243) {
  animation-delay: 12.15s;
}

path:nth-child(244) {
  animation-delay: 12.2s;
}

path:nth-child(245) {
  animation-delay: 12.25s;
}

path:nth-child(246) {
  animation-delay: 12.3s;
}

path:nth-child(247) {
  animation-delay: 12.35s;
}

path:nth-child(248) {
  animation-delay: 12.4s;
}

path:nth-child(249) {
  animation-delay: 12.45s;
}

path:nth-child(250) {
  animation-delay: 12.5s;
}

path:nth-child(251) {
  animation-delay: 12.55s;
}

path:nth-child(252) {
  animation-delay: 12.6s;
}

path:nth-child(253) {
  animation-delay: 12.65s;
}

path:nth-child(254) {
  animation-delay: 12.7s;
}

path:nth-child(255) {
  animation-delay: 12.75s;
}

path:nth-child(256) {
  animation-delay: 12.8s;
}

path:nth-child(257) {
  animation-delay: 12.85s;
}

path:nth-child(258) {
  animation-delay: 12.9s;
}

path:nth-child(259) {
  animation-delay: 12.95s;
}

path:nth-child(260) {
  animation-delay: 13s;
}

path:nth-child(261) {
  animation-delay: 13.05s;
}

path:nth-child(262) {
  animation-delay: 13.1s;
}

path:nth-child(263) {
  animation-delay: 13.15s;
}

path:nth-child(264) {
  animation-delay: 13.2s;
}

path:nth-child(265) {
  animation-delay: 13.25s;
}

path:nth-child(266) {
  animation-delay: 13.3s;
}

path:nth-child(267) {
  animation-delay: 13.35s;
}

path:nth-child(268) {
  animation-delay: 13.4s;
}

path:nth-child(269) {
  animation-delay: 13.45s;
}

path:nth-child(270) {
  animation-delay: 13.5s;
}

path:nth-child(271) {
  animation-delay: 13.55s;
}

path:nth-child(272) {
  animation-delay: 13.6s;
}

path:nth-child(273) {
  animation-delay: 13.65s;
}

path:nth-child(274) {
  animation-delay: 13.7s;
}

path:nth-child(275) {
  animation-delay: 13.75s;
}

path:nth-child(276) {
  animation-delay: 13.8s;
}

path:nth-child(277) {
  animation-delay: 13.85s;
}

path:nth-child(278) {
  animation-delay: 13.9s;
}

path:nth-child(279) {
  animation-delay: 13.95s;
}

path:nth-child(280) {
  animation-delay: 14s;
}

path:nth-child(281) {
  animation-delay: 14.05s;
}

path:nth-child(282) {
  animation-delay: 14.1s;
}

path:nth-child(283) {
  animation-delay: 14.15s;
}

path:nth-child(284) {
  animation-delay: 14.2s;
}

path:nth-child(285) {
  animation-delay: 14.25s;
}

path:nth-child(286) {
  animation-delay: 14.3s;
}

path:nth-child(287) {
  animation-delay: 14.35s;
}

path:nth-child(288) {
  animation-delay: 14.4s;
}

path:nth-child(289) {
  animation-delay: 14.45s;
}

path:nth-child(290) {
  animation-delay: 14.5s;
}

path:nth-child(291) {
  animation-delay: 14.55s;
}

path:nth-child(292) {
  animation-delay: 14.6s;
}

path:nth-child(293) {
  animation-delay: 14.65s;
}

path:nth-child(294) {
  animation-delay: 14.7s;
}

path:nth-child(295) {
  animation-delay: 14.75s;
}

path:nth-child(296) {
  animation-delay: 14.8s;
}

path:nth-child(297) {
  animation-delay: 14.85s;
}

path:nth-child(298) {
  animation-delay: 14.9s;
}

path:nth-child(299) {
  animation-delay: 14.95s;
}

path:nth-child(300) {
  animation-delay: 15s;
}

path:nth-child(301) {
  animation-delay: 15.05s;
}

path:nth-child(302) {
  animation-delay: 15.1s;
}

path:nth-child(303) {
  animation-delay: 15.15s;
}

path:nth-child(304) {
  animation-delay: 15.2s;
}

path:nth-child(305) {
  animation-delay: 15.25s;
}

path:nth-child(306) {
  animation-delay: 15.3s;
}

path:nth-child(307) {
  animation-delay: 15.35s;
}

path:nth-child(308) {
  animation-delay: 15.4s;
}

path:nth-child(309) {
  animation-delay: 15.45s;
}

path:nth-child(310) {
  animation-delay: 15.5s;
}

path:nth-child(311) {
  animation-delay: 15.55s;
}

path:nth-child(312) {
  animation-delay: 15.6s;
}

path:nth-child(313) {
  animation-delay: 15.65s;
}

path:nth-child(314) {
  animation-delay: 15.7s;
}

path:nth-child(315) {
  animation-delay: 15.75s;
}

path:nth-child(316) {
  animation-delay: 15.8s;
}

path:nth-child(317) {
  animation-delay: 15.85s;
}

path:nth-child(318) {
  animation-delay: 15.9s;
}

path:nth-child(319) {
  animation-delay: 15.95s;
}

path:nth-child(320) {
  animation-delay: 16s;
}

path:nth-child(321) {
  animation-delay: 16.05s;
}

path:nth-child(322) {
  animation-delay: 16.1s;
}

path:nth-child(323) {
  animation-delay: 16.15s;
}

path:nth-child(324) {
  animation-delay: 16.2s;
}

path:nth-child(325) {
  animation-delay: 16.25s;
}

path:nth-child(326) {
  animation-delay: 16.3s;
}

path:nth-child(327) {
  animation-delay: 16.35s;
}

path:nth-child(328) {
  animation-delay: 16.4s;
}

path:nth-child(329) {
  animation-delay: 16.45s;
}

path:nth-child(330) {
  animation-delay: 16.5s;
}

path:nth-child(331) {
  animation-delay: 16.55s;
}

path:nth-child(332) {
  animation-delay: 16.6s;
}

path:nth-child(333) {
  animation-delay: 16.65s;
}

path:nth-child(334) {
  animation-delay: 16.7s;
}

path:nth-child(335) {
  animation-delay: 16.75s;
}

path:nth-child(336) {
  animation-delay: 16.8s;
}

path:nth-child(337) {
  animation-delay: 16.85s;
}

path:nth-child(338) {
  animation-delay: 16.9s;
}

path:nth-child(339) {
  animation-delay: 16.95s;
}

path:nth-child(340) {
  animation-delay: 17s;
}

path:nth-child(341) {
  animation-delay: 17.05s;
}

path:nth-child(342) {
  animation-delay: 17.1s;
}

path:nth-child(343) {
  animation-delay: 17.15s;
}

path:nth-child(344) {
  animation-delay: 17.2s;
}

path:nth-child(345) {
  animation-delay: 17.25s;
}

path:nth-child(346) {
  animation-delay: 17.3s;
}

path:nth-child(347) {
  animation-delay: 17.35s;
}

path:nth-child(348) {
  animation-delay: 17.4s;
}

path:nth-child(349) {
  animation-delay: 17.45s;
}

path:nth-child(350) {
  animation-delay: 17.5s;
}

path:nth-child(351) {
  animation-delay: 17.55s;
}

path:nth-child(352) {
  animation-delay: 17.6s;
}

path:nth-child(353) {
  animation-delay: 17.65s;
}

path:nth-child(354) {
  animation-delay: 17.7s;
}

path:nth-child(355) {
  animation-delay: 17.75s;
}

path:nth-child(356) {
  animation-delay: 17.8s;
}

path:nth-child(357) {
  animation-delay: 17.85s;
}

path:nth-child(358) {
  animation-delay: 17.9s;
}

path:nth-child(359) {
  animation-delay: 17.95s;
}

path:nth-child(360) {
  animation-delay: 18s;
}

path:nth-child(361) {
  animation-delay: 18.05s;
}

path:nth-child(362) {
  animation-delay: 18.1s;
}

path:nth-child(363) {
  animation-delay: 18.15s;
}

path:nth-child(364) {
  animation-delay: 18.2s;
}

path:nth-child(365) {
  animation-delay: 18.25s;
}

path:nth-child(366) {
  animation-delay: 18.3s;
}

path:nth-child(367) {
  animation-delay: 18.35s;
}

path:nth-child(368) {
  animation-delay: 18.4s;
}

path:nth-child(369) {
  animation-delay: 18.45s;
}

path:nth-child(370) {
  animation-delay: 18.5s;
}

path:nth-child(371) {
  animation-delay: 18.55s;
}

path:nth-child(372) {
  animation-delay: 18.6s;
}

path:nth-child(373) {
  animation-delay: 18.65s;
}

path:nth-child(374) {
  animation-delay: 18.7s;
}

path:nth-child(375) {
  animation-delay: 18.75s;
}

path:nth-child(376) {
  animation-delay: 18.8s;
}

path:nth-child(377) {
  animation-delay: 18.85s;
}

path:nth-child(378) {
  animation-delay: 18.9s;
}

path:nth-child(379) {
  animation-delay: 18.95s;
}

path:nth-child(380) {
  animation-delay: 19s;
}

path:nth-child(381) {
  animation-delay: 19.05s;
}

path:nth-child(382) {
  animation-delay: 19.1s;
}

path:nth-child(383) {
  animation-delay: 19.15s;
}

path:nth-child(384) {
  animation-delay: 19.2s;
}

path:nth-child(385) {
  animation-delay: 19.25s;
}

path:nth-child(386) {
  animation-delay: 19.3s;
}

path:nth-child(387) {
  animation-delay: 19.35s;
}

path:nth-child(388) {
  animation-delay: 19.4s;
}

path:nth-child(389) {
  animation-delay: 19.45s;
}

path:nth-child(390) {
  animation-delay: 19.5s;
}

path:nth-child(391) {
  animation-delay: 19.55s;
}

path:nth-child(392) {
  animation-delay: 19.6s;
}

path:nth-child(393) {
  animation-delay: 19.65s;
}

path:nth-child(394) {
  animation-delay: 19.7s;
}

path:nth-child(395) {
  animation-delay: 19.75s;
}

path:nth-child(396) {
  animation-delay: 19.8s;
}

path:nth-child(397) {
  animation-delay: 19.85s;
}

path:nth-child(398) {
  animation-delay: 19.9s;
}

path:nth-child(399) {
  animation-delay: 19.95s;
}

path:nth-child(400) {
  animation-delay: 20s;
}

path:nth-child(401) {
  animation-delay: 20.05s;
}

path:nth-child(402) {
  animation-delay: 20.1s;
}

path:nth-child(403) {
  animation-delay: 20.15s;
}

path:nth-child(404) {
  animation-delay: 20.2s;
}

path:nth-child(405) {
  animation-delay: 20.25s;
}

path:nth-child(406) {
  animation-delay: 20.3s;
}

path:nth-child(407) {
  animation-delay: 20.35s;
}

path:nth-child(408) {
  animation-delay: 20.4s;
}

path:nth-child(409) {
  animation-delay: 20.45s;
}

path:nth-child(410) {
  animation-delay: 20.5s;
}

path:nth-child(411) {
  animation-delay: 20.55s;
}

path:nth-child(412) {
  animation-delay: 20.6s;
}

path:nth-child(413) {
  animation-delay: 20.65s;
}

path:nth-child(414) {
  animation-delay: 20.7s;
}

path:nth-child(415) {
  animation-delay: 20.75s;
}

path:nth-child(416) {
  animation-delay: 20.8s;
}

path:nth-child(417) {
  animation-delay: 20.85s;
}

path:nth-child(418) {
  animation-delay: 20.9s;
}

path:nth-child(419) {
  animation-delay: 20.95s;
}

path:nth-child(420) {
  animation-delay: 21s;
}

path:nth-child(421) {
  animation-delay: 21.05s;
}

path:nth-child(422) {
  animation-delay: 21.1s;
}

path:nth-child(423) {
  animation-delay: 21.15s;
}

path:nth-child(424) {
  animation-delay: 21.2s;
}

path:nth-child(425) {
  animation-delay: 21.25s;
}

path:nth-child(426) {
  animation-delay: 21.3s;
}

path:nth-child(427) {
  animation-delay: 21.35s;
}

path:nth-child(428) {
  animation-delay: 21.4s;
}

path:nth-child(429) {
  animation-delay: 21.45s;
}

path:nth-child(430) {
  animation-delay: 21.5s;
}

path:nth-child(431) {
  animation-delay: 21.55s;
}

path:nth-child(432) {
  animation-delay: 21.6s;
}

path:nth-child(433) {
  animation-delay: 21.65s;
}

path:nth-child(434) {
  animation-delay: 21.7s;
}

path:nth-child(435) {
  animation-delay: 21.75s;
}

path:nth-child(436) {
  animation-delay: 21.8s;
}

path:nth-child(437) {
  animation-delay: 21.85s;
}

path:nth-child(438) {
  animation-delay: 21.9s;
}

path:nth-child(439) {
  animation-delay: 21.95s;
}

path:nth-child(440) {
  animation-delay: 22s;
}

path:nth-child(441) {
  animation-delay: 22.05s;
}

path:nth-child(442) {
  animation-delay: 22.1s;
}

path:nth-child(443) {
  animation-delay: 22.15s;
}

path:nth-child(444) {
  animation-delay: 22.2s;
}

path:nth-child(445) {
  animation-delay: 22.25s;
}

path:nth-child(446) {
  animation-delay: 22.3s;
}

path:nth-child(447) {
  animation-delay: 22.35s;
}

path:nth-child(448) {
  animation-delay: 22.4s;
}

path:nth-child(449) {
  animation-delay: 22.45s;
}

path:nth-child(450) {
  animation-delay: 22.5s;
}

path:nth-child(451) {
  animation-delay: 22.55s;
}

path:nth-child(452) {
  animation-delay: 22.6s;
}

path:nth-child(453) {
  animation-delay: 22.65s;
}

path:nth-child(454) {
  animation-delay: 22.7s;
}

path:nth-child(455) {
  animation-delay: 22.75s;
}

path:nth-child(456) {
  animation-delay: 22.8s;
}

path:nth-child(457) {
  animation-delay: 22.85s;
}

path:nth-child(458) {
  animation-delay: 22.9s;
}

path:nth-child(459) {
  animation-delay: 22.95s;
}

path:nth-child(460) {
  animation-delay: 23s;
}

path:nth-child(461) {
  animation-delay: 23.05s;
}

path:nth-child(462) {
  animation-delay: 23.1s;
}

path:nth-child(463) {
  animation-delay: 23.15s;
}

path:nth-child(464) {
  animation-delay: 23.2s;
}

path:nth-child(465) {
  animation-delay: 23.25s;
}

path:nth-child(466) {
  animation-delay: 23.3s;
}

path:nth-child(467) {
  animation-delay: 23.35s;
}

path:nth-child(468) {
  animation-delay: 23.4s;
}

path:nth-child(469) {
  animation-delay: 23.45s;
}

path:nth-child(470) {
  animation-delay: 23.5s;
}

path:nth-child(471) {
  animation-delay: 23.55s;
}

path:nth-child(472) {
  animation-delay: 23.6s;
}

path:nth-child(473) {
  animation-delay: 23.65s;
}

path:nth-child(474) {
  animation-delay: 23.7s;
}

path:nth-child(475) {
  animation-delay: 23.75s;
}

path:nth-child(476) {
  animation-delay: 23.8s;
}

path:nth-child(477) {
  animation-delay: 23.85s;
}

path:nth-child(478) {
  animation-delay: 23.9s;
}

path:nth-child(479) {
  animation-delay: 23.95s;
}

path:nth-child(480) {
  animation-delay: 24s;
}

path:nth-child(481) {
  animation-delay: 24.05s;
}

path:nth-child(482) {
  animation-delay: 24.1s;
}

path:nth-child(483) {
  animation-delay: 24.15s;
}

path:nth-child(484) {
  animation-delay: 24.2s;
}

path:nth-child(485) {
  animation-delay: 24.25s;
}

path:nth-child(486) {
  animation-delay: 24.3s;
}

path:nth-child(487) {
  animation-delay: 24.35s;
}

path:nth-child(488) {
  animation-delay: 24.4s;
}

path:nth-child(489) {
  animation-delay: 24.45s;
}

path:nth-child(490) {
  animation-delay: 24.5s;
}

path:nth-child(491) {
  animation-delay: 24.55s;
}

path:nth-child(492) {
  animation-delay: 24.6s;
}

path:nth-child(493) {
  animation-delay: 24.65s;
}

path:nth-child(494) {
  animation-delay: 24.7s;
}

path:nth-child(495) {
  animation-delay: 24.75s;
}

path:nth-child(496) {
  animation-delay: 24.8s;
}

path:nth-child(497) {
  animation-delay: 24.85s;
}

path:nth-child(498) {
  animation-delay: 24.9s;
}

path:nth-child(499) {
  animation-delay: 24.95s;
}

path:nth-child(500) {
  animation-delay: 25s;
}

path:nth-child(501) {
  animation-delay: 25.05s;
}

path:nth-child(502) {
  animation-delay: 25.1s;
}

path:nth-child(503) {
  animation-delay: 25.15s;
}

path:nth-child(504) {
  animation-delay: 25.2s;
}

path:nth-child(505) {
  animation-delay: 25.25s;
}

path:nth-child(506) {
  animation-delay: 25.3s;
}

path:nth-child(507) {
  animation-delay: 25.35s;
}

path:nth-child(508) {
  animation-delay: 25.4s;
}

path:nth-child(509) {
  animation-delay: 25.45s;
}

path:nth-child(510) {
  animation-delay: 25.5s;
}

path:nth-child(511) {
  animation-delay: 25.55s;
}

path:nth-child(512) {
  animation-delay: 25.6s;
}

path:nth-child(513) {
  animation-delay: 25.65s;
}

path:nth-child(514) {
  animation-delay: 25.7s;
}

path:nth-child(515) {
  animation-delay: 25.75s;
}

path:nth-child(516) {
  animation-delay: 25.8s;
}

path:nth-child(517) {
  animation-delay: 25.85s;
}

path:nth-child(518) {
  animation-delay: 25.9s;
}

path:nth-child(519) {
  animation-delay: 25.95s;
}

path:nth-child(520) {
  animation-delay: 26s;
}

path:nth-child(521) {
  animation-delay: 26.05s;
}

path:nth-child(522) {
  animation-delay: 26.1s;
}

path:nth-child(523) {
  animation-delay: 26.15s;
}

path:nth-child(524) {
  animation-delay: 26.2s;
}

path:nth-child(525) {
  animation-delay: 26.25s;
}


@media(max-width:991px) {
  .banner_left svg {
    width: 240px;
    height: 250px;
  }
}